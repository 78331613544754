import { faMobile, faPlus } from "@fortawesome/free-solid-svg-icons";
import Skill from "../../models/Skill";

import {
  faFigma,
  faHtml5,
  faJs,
  faReact,
  faSketch,
  faSwift,
  faVuejs,
} from "@fortawesome/free-brands-svg-icons";

const skillData: Skill[] = [
  {
    iconImg: "https://www.svgrepo.com/download/374144/typescript.svg",
    color: "#3178C6",
    text: "TypeScript",
  },
  { icon: faJs, color: "#fade4d", text: "JavaScript" },
  { icon: faSwift, color: "#eb5d42", text: "Swift" },
  { icon: faHtml5, color: "#e16d33", text: "HTML / CSS" },
  { icon: faReact, color: "#134A78", text: "React" },
  { icon: faVuejs, color: "#64b184", text: "Vue" },
  { icon: faMobile, color: "#82d7f4", text: "React Native" },
  { icon: faSketch, color: "#e2722D", text: "Sketch" },
  { icon: faFigma, color: "#9565F6", text: "Figma" },
  { icon: faPlus, color: "#212124", text: "And more" },
];

export default skillData;

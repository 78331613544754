import React from "react";

import "./styles.scss";

export interface TagProps {
  title: string;
  color?: string;
}

const Tag: React.FC<TagProps> = ({ title, color }) => {
  const baseClass = "tag";

  return (
    <span
      className={baseClass}
      style={color ? { backgroundColor: `var(--${color}-a10)` } : {}}
    >
      <p className="tag-text" style={color ? { color: `var(--${color})` } : {}}>
        {title}
      </p>
    </span>
  );
};

export default Tag;

import React, { useContext } from "react";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faArrowDown } from "@fortawesome/free-solid-svg-icons";

export interface SquareIconProps {
  icon: IconDefinition;
  color?: string;
}

const SquareIcon: React.FC<SquareIconProps> = ({ icon, color }) => {
  return (
    <div
      className="square-icon-container"
      style={
        color
          ? {
              border: `1px solid var(--${color})`,
              boxShadow: `4px 4px 0 var(--${color})`,
            }
          : {}
      }
    >
      <FontAwesomeIcon
        icon={icon}
        className="square-icon"
        style={color ? { color: `var(--${color})` } : {}}
      />
    </div>
  );
};

SquareIcon.displayName = "SquareIcon";

export default SquareIcon;

import React from "react";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";

export interface AboutMeItemProps {
  title?: String;
  subtitle?: String;
}

const AboutMeItem: React.FC<AboutMeItemProps> = ({ title, subtitle }) => {
  return (
    <>
      <FontAwesomeIcon icon={faStarOfLife} className="separator-icon" />
      <p className="about-me-item-title">
        <span className="about-me-item-subtitle">{subtitle}</span>
        {title}
      </p>
    </>
  );
};

AboutMeItem.displayName = "AboutMeItem";

export default AboutMeItem;

import React from "react";
import "./styles.scss";

import ExperienceCell from "../../../components/ExperienceCell";
import workData from "../../../assets/data/work";

const WorkSection: React.FC = () => {
  return (
    <section className="work-section" id="work">
      <h2>Work Experience</h2>
      <ul className="work-list">
        {workData.map((work, index) => (
          <ExperienceCell
            key={index}
            role={work.role}
            date={work.date}
            date_second={work.date_second}
            company={work.company}
            link={work.link}
            city={work.city}
            info={work.info}
            keywords={work.keywords}
          >
            <div className="work-section-cell-container">
              {work.content &&
                work.content.map((element, index) => {
                  return (
                    <div className="work-section-cell" key={index}>
                      {element.date && (
                        <p className="work-section-date">{element.date}</p>
                      )}
                      {element.children}
                    </div>
                  );
                })}
            </div>
          </ExperienceCell>
        ))}
      </ul>
    </section>
  );
};

export default WorkSection;

import React from "react";
import clsx from "clsx";

import "./styles.scss";

export interface MarqueeProps {
  gap?: Number;
  duration?: Number;
  isRunning?: Boolean;

  className?: string;

  children: React.ReactNode;
}

const Marquee: React.FC<MarqueeProps> = ({
  gap = 10,
  duration = 60,
  isRunning = true,
  className,
  children,
}) => {
  const baseClass = "ui-marquee";

  const rootClass = clsx(baseClass, className);

  return (
    <div
      className={rootClass}
      style={{
        ["--gap" as any]: gap + "px",
        ["--duration" as any]: duration + "s",
        ["--play-state" as any]: isRunning ? "running" : "paused",
      }}
    >
      <div className={baseClass + "-group"}>{children}</div>
      <div className={baseClass + "-group"} aria-hidden="true">
        {children}
      </div>
    </div>
  );
};

Marquee.displayName = "Marquee";

Marquee.defaultProps = {
  gap: 20,
  duration: 28,
  className: "",
};

export default Marquee;

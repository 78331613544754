import React, { useContext } from "react";
import clsx from "clsx";

import "./styles.scss";
import ThemeContext from "../../contexts/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

export interface CircleTextProps {
  className?: string;
  text: string;
  revolutionDuration?: Number;

  onClick?: () => void;
}

const CircleText: React.FC<CircleTextProps> = ({
  className,
  text,
  revolutionDuration = 20,
  onClick,
}) => {
  const { dark } = useContext(ThemeContext);

  const baseClass = "circle-text";

  const rootClass = clsx(baseClass, className);

  return (
    <div className="circle-text-container" onClick={onClick}>
      <div
        className="circle-text"
        style={{
          ["--revolutionDuration" as any]: revolutionDuration + "s",
        }}
      >
        <svg
          x="0px"
          y="0px"
          width="300px"
          height="300px"
          viewBox="0 0 300 300"
          enableBackground="new 0 0 300 300"
        >
          <defs>
            <path
              id="circlePath"
              d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
            />
          </defs>
          <circle cx="150" cy="100" r="75" fill="none" />
          <g>
            <use xlinkHref="#circlePath" fill="none" />
            <text fill={dark ? "#FFF" : "#000"}>
              <textPath xlinkHref="#circlePath">{text}</textPath>
            </text>
          </g>
        </svg>
      </div>
      <div className="circle-text-arrow">
        <FontAwesomeIcon icon={faArrowDown} fontSize={22} />
      </div>
    </div>
  );
};

CircleText.displayName = "CircleText";

CircleText.defaultProps = {
  className: "",
  onClick: () => {},
};

export default CircleText;

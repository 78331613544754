import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import FontFaceObserver from "fontfaceobserver";

// Define the type for the context
interface FontLoaderContextType {
  fontsLoaded: boolean;
}

// Create a context with initial values
const FontLoaderContext = createContext<FontLoaderContextType>({
  fontsLoaded: false,
});

// Define props type for FontProvider
interface FontLoaderProviderProps {
  children: React.ReactNode;
}

// Create a provider component
export const FontLoaderProvider: FC<FontLoaderProviderProps> = ({
  children,
}) => {
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const font1 = new FontFaceObserver("Unbounded");
    const font2 = new FontFaceObserver("Nunito");

    Promise.all([font1.load(), font2.load()])
      .then(() => {
        setFontsLoaded(true);
      })
      .catch((error) => {
        console.error("Font loading failed:", error);
      });
  }, []);

  return (
    <FontLoaderContext.Provider value={{ fontsLoaded }}>
      {children}
    </FontLoaderContext.Provider>
  );
};

// Create a custom hook to use the context
export const useFontLoader = () => useContext(FontLoaderContext);

export default FontLoaderContext;

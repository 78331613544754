import {
  faDribbble,
  faGithub,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import SocialLink from "../../models/SocialLink";

const socialLinksData: SocialLink[] = [
  {
    icon: faTwitter,
    name: "twitter",
    link: "https://twitter.com/alex_toubiana",
  },
  {
    icon: faLinkedinIn,
    name: "linkedin",
    link: "https://www.linkedin.com/in/alexandre-toubiana",
  },
  {
    icon: faGithub,
    name: "github",
    link: "https://github.com/alextoub",
  },
  {
    icon: faDribbble,
    name: "dribbble",
    link: "https://dribbble.com/alextoub",
  },
];

export default socialLinksData;

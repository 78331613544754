import React from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skill from "../../models/Skill";

const SkillCell: React.FC<Skill> = ({ icon, iconImg, color, text }) => (
  <span className="skill-cell">
    <span
      className="skill-cell-icon"
      style={color ? { backgroundColor: `${color}` } : {}}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon} color="white" />
      ) : iconImg ? (
        <img
          alt={text}
          src={iconImg}
          className="skill-cell-icon-img"
          style={{ height: "14px" }}
        />
      ) : null}
    </span>
    <span className="skill-cell-text">{text}</span>
  </span>
);

export default SkillCell;

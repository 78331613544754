import "./styles/App.scss";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import { FontLoaderProvider } from "./contexts/FontLoaderContext";

import TopBar from "./components/TopBar";
import AppNavigator from "./navigation/App.navigator";

function App() {
  return (
    <BrowserRouter>
      <FontLoaderProvider>
        <ThemeProvider>
          <TopBar />
          <div className="app-wrapper">
            <AppNavigator />
          </div>
        </ThemeProvider>
      </FontLoaderProvider>
    </BrowserRouter>
  );
}

export default App;

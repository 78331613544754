import { Routes, Route } from "react-router-dom";

import Home from "../pages/HomePage";
import Works from "../pages/WorksPage";
import Projects from "../pages/ProjectsPage";
import About from "../pages/AboutPage";

const AppNavigator = () => {
  return (
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path="works" element={<Works />} />
      <Route path="about" element={<About />} />
      <Route path="projects" element={<Projects />} />
    </Routes>
  );
};

export default AppNavigator;

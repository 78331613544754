import "./styles.scss";
import logo from "../../assets/logo.png";

import { Link, NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../contexts/ThemeContext";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import DarkModeToggle from "../DarkModeToggle";
import * as Scroll from "react-scroll";

const TopBar = () => {
  const [isToggleDropdown, setIsToggleDropdown] = useState(false);

  useEffect(() => {
    window
      .matchMedia("(min-width: 600px)")
      .addEventListener("change", (e) => setIsToggleDropdown(false));
  }, []);

  const NavLinks = () => {
    let links = ["work", "education", "skills"];
    let nav_links: React.ReactNode;

    nav_links = links.map((link, index) => (
      <Scroll.Link
        key={index}
        offset={-100}
        spy
        hashSpy
        smooth
        activeClass="topbar-link-container--active"
        className="topbar-link-container"
        to={link}
      >
        <p className="topbar-link">{link}</p>
      </Scroll.Link>
    ));

    return nav_links;
  };

  return (
    <nav className="topbar">
      <div className="topbar-container">
        <Link
          className="topbar-brand"
          to="/"
          onClick={() => setIsToggleDropdown(false)}
        >
          <img
            alt="logo"
            src={logo}
            className="img-fluid"
            style={{ height: "34px" }}
          />
        </Link>
        <div className="topbar-links">
          {NavLinks()}
          <DarkModeToggle />
        </div>
        <div className="topbar-buttons-mobile">
          <DarkModeToggle />
          <div
            className="topbar-dropdown-indicator"
            onClick={() => setIsToggleDropdown(!isToggleDropdown)}
          >
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </div>
      {isToggleDropdown ? (
        <div className="dropdown-container">
          <div className="topbar-links-mobile">{NavLinks()}</div>
        </div>
      ) : null}
    </nav>
  );
};

export default TopBar;

import React from "react";
import "./styles.scss";

import ExperienceCell from "../../../components/ExperienceCell";
import educationData from "../../../assets/data/education";

const EducationSection: React.FC = () => {
  return (
    <section className="education-section" id="education">
      <h2>Education</h2>
      <ul className="education-list">
        {educationData.map((education, index) => (
          <ExperienceCell
            key={index}
            role={education.role}
            date={education.date}
            company={education.company}
            city={education.city}
            link={education.link}
          />
        ))}
      </ul>
    </section>
  );
};

export default EducationSection;

import React from "react";
import "./styles.scss";

import SkillCell from "../../../components/SkillCell";
import skillData from "../../../assets/data/skill";

const SkillSection: React.FC = () => {
  return (
    <section className="skills-section" id="skills">
      <h2>Skills / Tools</h2>
      <div className="skills-lines">
        {skillData.map((skill, index) => (
          <SkillCell
            key={index}
            icon={skill.icon}
            iconImg={skill.iconImg}
            color={skill.color}
            text={skill.text}
          />
        ))}
      </div>
    </section>
  );
};

export default SkillSection;

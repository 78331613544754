import React, { useState, useLayoutEffect } from "react";

const ThemeContext = React.createContext({
  dark: false,
  toggle: () => {},
});

export default ThemeContext;

export function ThemeProvider(props: any) {
  const [dark, setDark] = useState(false);

  const applyTheme = (theme: any) => {
    const root = document.getElementsByTagName("html")[0];
    root.style.cssText = theme.join(";");
    if (dark) {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", "#121212");
    } else {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", "#FFFFFF");
    }
  };

  useLayoutEffect(() => {
    let lastTheme = window.localStorage.getItem("darkTheme");

    if (lastTheme === null) {
      let userTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
      lastTheme = userTheme.toString();
    }

    if (lastTheme === "true") {
      setDark(true);
      applyTheme(darkTheme);
    } else {
      setDark(false);
      applyTheme(lightTheme);
    }
  }, [dark, applyTheme]);

  const toggle = () => {
    const body = document.getElementsByTagName("body")[0];
    body.style.cssText = "transition: background 0.2s ease";

    setDark(!dark);
    window.localStorage.setItem("darkTheme", (!dark).toString());
  };

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggle,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}

// styles
const lightTheme = [
  //Texts
  "--text: #032B43",
  "--subtitle: #637482",

  //App
  "--background: #FFFFFF",
  "--card: #F5F6FA",
  "--background-nav: rgba(255, 255, 255, 0.8)",
  "--border: #e1e1e1",

  //Colors
  "--green: #136F63",
  "--yellow: #FFBA08",
  "--blue: #3F88C5",
  "--dark_blue: #032B43",
  "--red: #D00000",
  "--white: #FFFFFF",

  //Colors A.1
  "--green-a10: rgb(19, 111, 99, 0.1)",
  "--yellow-a10: rgb(255, 186, 8, 0.1)",
  "--blue-a10: rgb(63, 136, 197, 0.1)",
  "--dark_blue-a10: rgb(3, 43, 67, 0.1)",
  "--red-a10: rgb(208, 0, 0, 0.1)",
  "--white-a10: rgb(255, 255, 255, 0.1)",
];

const darkTheme = [
  //Texts
  "--text: #FFFFFF",
  "--subtitle: #6d7f8d",

  //App
  "--background: #121212",
  "--card: #212124",
  "--background-nav: rgba(0, 0, 0, 0.8)",
  "--border: #e1e1e1",

  //Colors
  "--green: #136F63",
  "--yellow: #FFBA08",
  "--blue: #3F88C5",
  "--dark_blue: #032B43",
  "--red: #D00000",
  "--white: #FFFFFF",

  //Colors A.1
  "--green-a10: rgb(19, 111, 99, 0.1)",
  "--yellow-a10: rgb(255, 186, 8, 0.1)",
  "--blue-a10: rgb(63, 136, 197, 0.1)",
  "--dark_blue-a10: rgb(3, 43, 67, 0.1)",
  "--red-a10: rgb(208, 0, 0, 0.1)",
  "--white-a10: rgb(255, 255, 255, 0.1)",
];

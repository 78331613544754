import "./styles.scss"

const WorksPage = () => {
  return (
    <div className="works">
      <h1>Works</h1>
    </div>
  );
};

export default WorksPage;

import {
  faCode,
  faLayerGroup,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import Activity from "../../models/Activity";
import Experience from "../../models/Experience";
import dayjs from "dayjs";

const activityData: Activity[] = [
  {
    title: "Mobile / Web Software engineer",
    description:
      "I specialize in web and mobile development, leveraging my extensive experience to create robust and scalable solutions. I have proficiency in various programming languages such as JS/TS and Swift, allowing me to develop high-quality software that aligns with modern technological requirements.",
    tag: `${dayjs().diff("2014-09-01", "years")} years of experience`,
    color: "green",
    icon: faCode,
  },
  {
    title: "UI / UX design",
    description:
      "Passionate about UI design, I strive to create visually appealing and intuitive interfaces that enhance user experiences. By leveraging industry-standard tools and my understanding of user behavior, I ensure that every design decision contributes to a seamless and engaging user journey.",
    tag: `${dayjs().diff("2014-09-01", "years") + 1}+ years free time passion`,
    color: "yellow",
    icon: faPalette,
  },
  {
    title: "Product manager",
    description:
      "With a strong background in product management, I oversee projects from concept to delivery, prioritizing user experience and business goals. Through effective communication and strategic planning, I ensure that projects are completed on time, exceeding expectations.",
    tag: `${dayjs().diff("2020-02-01", "years")}+ years role`,
    color: "blue",
    icon: faLayerGroup,
  },
];

export default activityData;

import React from "react";
import clsx from "clsx";

import "./styles.scss";

export interface ButtonProps {
  outline?: boolean;
  full?: boolean;
  disabled?: boolean;

  color?: string;
  className?: string;

  children: React.ReactNode;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  outline = false,
  full = false,
  disabled = false,
  color = "blue",
  className,
  children,
  onClick,
}) => {
  const baseClass = "ui-button";

  const rootClass = clsx(
    baseClass,
    color && `${baseClass}-${color}`,
    outline && `${baseClass}-${color}-outline`,
    full && `${baseClass}-full`,
    className
  );

  return (
    <a
      className={rootClass}
      onClick={onClick}
      // disabled={disabled}
    >
      {children}
    </a>
  );
};

Button.displayName = "Button";

Button.defaultProps = {
  outline: false,
  full: false,
  color: "blue",
  className: "",
  disabled: false,
  onClick: () => {},
};

export default Button;

import Experience from "../../models/Experience";

const educationData: Experience[] = [
  {
    role: "Master's Degree - Software Engineering",
    date: "2014 - 2019",
    company: "EPITA",
    city: "Paris, FR",
    link: "https://www.epita.fr/en/",
  },
  {
    role: "French High School Diploma",
    date: "2011 - 2014",
    company: "École Progress",
    city: "Paris, FR",
  },
];

export default educationData;

import React from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faMapPin } from "@fortawesome/free-solid-svg-icons";
import Tag from "../Tag";
import Experience from "../../models/Experience";

const ExperienceCell: React.FC<Experience> = ({
  role,
  date,
  date_second,
  company,
  link,
  city,
  info,
  keywords,
  children,
}) => {
  return (
    <li className="experience-cell">
      <div className="experience-cell-title-container">
        <h3>{role}</h3>
        <h3 className="experience-cell-date">
          {date}
          {date_second && <br />}
          {date_second}
        </h3>
      </div>
      <div className="experience-cell-title-container">
        <div className="experience-cell-subtitle-container">
          <div className="experience-icon-text">
            <FontAwesomeIcon icon={faBuilding} className="experience-text" />
            <a className="experience-text" href={link} target="_blank">
              {company}
            </a>
          </div>
          <div className="experience-icon-text">
            <FontAwesomeIcon icon={faMapPin} className="experience-text" />
            <p className="experience-text">{city}</p>
          </div>
        </div>
        {info ? <Tag title={info} color="green" /> : null}
      </div>
      {children ? (
        <div className="experience-cell-container">{children}</div>
      ) : null}
      <div className="experience-cell-keywords">
        {keywords &&
          keywords.map((keyword) => (
            <Tag key={keyword} title={keyword} color="blue" />
          ))}
      </div>
    </li>
  );
};

export default ExperienceCell;

import "./styles.scss"

const ProjectsPage = () => {
  return (
    <div className="projects">
      <h1>Projects</h1>
    </div>
  );
};

export default ProjectsPage;

import "./styles.scss"

const AboutPage = () => {
  return (
    <div className="about">
      <h1>About</h1>
    </div>
  );
};

export default AboutPage;

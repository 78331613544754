import React from "react";
import "./styles.scss";

import Marquee from "../../../components/Marquee";
import AboutMeItem from "../AboutMeItem";
import aboutData from "../../../assets/data/about";

const AboutLineSection: React.FC = () => {
  const [isRunning, setIsRunning] = React.useState(true);

  return (
    <section
      className="about-line-section"
      id="about"
      onMouseEnter={() => setIsRunning(false)}
      onMouseLeave={() => setIsRunning(true)}
    >
      <Marquee isRunning={isRunning}>
        {aboutData.map((about) => (
          <AboutMeItem
            title={about.title}
            subtitle={about.subtitle}
            key={about.title}
          />
        ))}
      </Marquee>
    </section>
  );
};

export default AboutLineSection;

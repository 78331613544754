import "./styles.scss";

import HomeSection from "./MainSection";
import AboutLineSection from "./AboutLineSection";
import ActivitySection from "./ActivitySection";
import WorkSection from "./WorkSection";
import EducationSection from "./EducationSection";
import SkillSection from "./SkillSection";
import Footer from "../../components/Footer";
import { useFontLoader } from "../../contexts/FontLoaderContext";

const HomePage = () => {
  const { fontsLoaded } = useFontLoader();
  if (!fontsLoaded) return null;
  return (
    <div className="home">
      <HomeSection />
      <AboutLineSection />
      <ActivitySection />
      <WorkSection />
      <EducationSection />
      <SkillSection />
      <Footer />
    </div>
  );
};

export default HomePage;

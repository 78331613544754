import React from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialLink from "../../models/SocialLink";
import socialLinksData from "../../assets/data/socialLink";
import dayjs from "dayjs";

const Footer: React.FC = () => {
  const links: SocialLink[] = socialLinksData;

  return (
    <section className="footer">
      <p>Made in {dayjs().year()} by Alexandre Toubiana</p>
      <div className="footer-icons">
        {links.map((social) => (
          <div
            key={social.name}
            className={`footer-icon footer-icon-${social.name}`}
            onClick={() => window.open(social.link, "_blank", "noreferrer")}
          >
            <FontAwesomeIcon icon={social.icon} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Footer;

import "./styles.scss";
import { useContext } from "react";
import ThemeContext from "../../contexts/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

const DarkModeToggle = () => {
  const { dark, toggle } = useContext(ThemeContext);

  const switchTheme = () => {
    toggle();
  };

  return (
    <div
      className={`dark-mode-toggle dark-mode-toggle-${dark ? "sun" : "moon"}`}
      onClick={switchTheme}
    >
      <FontAwesomeIcon icon={dark ? faSun : faMoon} />
    </div>
  );
};

export default DarkModeToggle;
